
import BroHeader from "@/components/headers/Header.vue";
import BroFooter from "@/components/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BroHeader,
    BroFooter,
  },
  setup() {
    return {};
  },
});
