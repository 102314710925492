import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nav row nav-text q-px-xs-xs q-px-lg-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rijksoverheid_logo = _resolveComponent("rijksoverheid-logo")!
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_q_header = _resolveComponent("q-header")!

  return (_openBlock(), _createBlock(_component_q_header, {
    class: "text-white bg-secondary",
    "height-hint": "198"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_rijksoverheid_logo),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_breadcrumbs, {
          class: _normalizeClass(_ctx.cssOverride)
        }, null, 8, ["class"])
      ])
    ]),
    _: 1
  }))
}