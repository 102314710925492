
import { Layout } from "@/layouts/layout";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const layout = computed(
      (): Layout => (route.meta.layout as Layout) || Layout.Default,
    );
    return {
      layout,
    };
  },
});
