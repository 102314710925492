
import { getBromonitorDates } from "@/api/bromonitor";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    const dates: { label: string; value: Date }[] = [];
    let date;
    const selectOptions: { label: string; value: unknown }[] = [];
    // const loaded = false;
    return { dates, selectOptions, date };
  },
  methods: {
    getDates() {
      const dateOptions: Intl.DateTimeFormatOptions = {
        timeZone: "Europe/Amsterdam",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const fetchDatesData = async (): Promise<void> => {
        this.$data.dates = (await getBromonitorDates()).dates.map(
          (d: Date) => ({
            label: new Date(d).toLocaleString("nl-NL", dateOptions),
            value: d,
          }),
        );
        this.$data.date = this.$data.dates[0];
        this.$data.selectOptions = this.$data.dates.map((x) => ({
          label: x.label,
          value: this.formatDate(x.value),
        }));
      };
      fetchDatesData();
    },
    formatDate(dateString) {
      return dateString.split("T")[0];
    },
    redirectToDateMonitor() {
      if (this.$data.date) {
        this.$router.push({
          name: "bro-monitor",
          query: { datum: this.$data.date },
        });
      }
    },
  },
  beforeMount() {
    this.getDates();
  },
});
