
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    cssOverride: {
      default:
        "offset-xs-0 col-xs-12 offset-md-2 col-md-8 q-px-xs-none q-px-lg-lg",
      type: String,
    },
  },
  setup() {
    return {};
  },
});
