export default {
  isoName: "nl" as string,
  nativeName: "Nederlands" as string,
  label: {
    clear: "Wis" as string,
    ok: "OK" as string,
    cancel: "Annuleer" as string,
    close: "Sluit" as string,
    set: "Pas toe" as string,
    select: "Selecteer" as string,
    reset: "Herinitialiseren" as string,
    remove: "Verwijder" as string,
    update: "Update" as string,
    create: "Maak aan" as string,
    search: "Zoek" as string,
    filter: "Filter" as string,
    refresh: "Ververs" as string,
    collapse: (label: string) => `Inklappen ${label}`,
    expand: (label: string) => `Uitklappen ${label}`,
  },
  date: {
    days: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'] as [string, string, string, string, string, string, string],
    daysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'] as [string, string, string, string, string, string, string],
    months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'] as [string, string, string, string, string, string, string, string, string, string, string, string],
    monthsShort: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'] as [string, string, string, string, string, string, string, string, string, string, string, string],
    firstDayOfWeek: 1 as number,
    format24h: true as boolean,
  },
  table: {
    noData: "Geen gegevens beschikbaar" as string,
    noResults: "Geen records gevonden" as string,
    loading: "Laden..." as string,
    selectedRecords: function (rows: number): string {
      return rows === 1
        ? "1 record geselecteerd."
        : (rows === 0 ? "Geen" : rows) + " geselecteerde records.";
    },
    recordsPerPage: "Records per pagina:" as string,
    allRows: "Alle" as string,
    pagination: function (start: number, end: number, total: number): string {
      return start + "-" + end + " van " + total.toLocaleString("nl-NL");
    },
    columns: "Kolommen" as string,
  },
  editor: {
    url: "URL" as string,
    bold: "Vet" as string,
    italic: "Cursief" as string,
    strikethrough: "Doorstrepen" as string,
    underline: "Onderstrepen" as string,
    unorderedList: "Ongeordende lijst" as string,
    orderedList: "Geordende lijst" as string,
    subscript: "Onderschrift" as string,
    superscript: "Bovenschrift" as string,
    hyperlink: "Hyperlink" as string,
    toggleFullscreen: "Volledig scherm" as string,
    quote: "Citaat" as string,
    left: "Links uitlijnen" as string,
    center: "Centreren" as string,
    right: "Rechts uitlijnen" as string,
    justify: "Uitvullen" as string,
    print: "Afdrukken" as string,
    outdent: "Minder inspringen" as string,
    indent: "Meer inspringen" as string,
    removeFormat: "Opmaak verwijderen" as string,
    formatting: "Opmaak" as string,
    fontSize: "Tekengrootte" as string,
    align: "Uitlijnen" as string,
    hr: "Horizontale lijn invoegen" as string,
    undo: "Herstel" as string,
    redo: "Opnieuw" as string,
    heading1: "Kop 1" as string,
    heading2: "Kop 2" as string,
    heading3: "Kop 3" as string,
    heading4: "Kop 4" as string,
    heading5: "Kop 5" as string,
    heading6: "Kop 6" as string,
    paragraph: "Paragraaf" as string,
    code: "Code" as string,
    size1: "Heel klein" as string,
    size2: "Klein" as string,
    size3: "Normaal" as string,
    size4: "Medium" as string,
    size5: "Groot" as string,
    size6: "Heel groot" as string,
    size7: "Maximum" as string,
    defaultFont: "Standaard lettertype" as string,
    viewSource: "Bekijk bron" as string,
  },
  tree: {
    noNodes: "Geen nodes beschikbaar" as string,
    noResults: "Geen overeenkomstige nodes gevonden" as string,
  },
};