import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bac6a0f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSources, (source) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "source",
        style: _normalizeStyle(_ctx.alignment()),
        key: source.name
      }, [
        _createTextVNode(" Bron: " + _toDisplayString(source.name.toUpperCase()) + ", ", 1),
        (source.types.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(source.types, (type) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "type",
                  style: _normalizeStyle(_ctx.alignment()),
                  key: type.type
                }, _toDisplayString(type.type.toUpperCase()) + ", " + _toDisplayString(_ctx.parseDate(type.updated)), 5))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(source.types, (type) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "type",
                  style: _normalizeStyle(_ctx.alignment()),
                  key: type.type
                }, _toDisplayString(_ctx.parseDate(type.updated)), 5))
              }), 128))
            ]))
      ], 4))
    }), 128))
  ]))
}