import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-008fda2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row bg-white justify-center header-white" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          alt: "Rijksoverheid Logo",
          src: require(`@/assets/${_ctx.logoSize()}`)
        }, null, 8, _hoisted_4)
      ])
    ])
  ]))
}