
import { downloadTable } from "@/services/downloadService";
import { defineComponent } from "vue";

interface BmTableRef {
  filteredSortedRows: unknown[];
}

export default defineComponent({
  data() {
    const filter = "";
    const pagination = {};
    const filteredRowCount = 0;
    return { filter, pagination, filteredRowCount };
  },
  props: {
    tableData: {
      type: Array as () => Array<{
        kvk: string;
        naam: string;
        identifiers: string;
        cpt: number;
        gmw: number;
        gld: number;
        gldAangevuld: number;
        gmn: number;
        sfr: number;
        bhrgt: number;
        bhrp: number;
      }>,
      default: () => [],
    },
    tableColumns: {
      type: Array as () => Array<{
        name: string;
        label: string;
        field: string;
        align: "left" | "right" | "center";
        sortable: boolean;
      }>,
      default: () => [],
    },
    tableFilter: String,
    rowKey: {
      type: [String, Function],
      default: undefined,
    },
    rowKeyLambda: {
      type: Function,
      default: undefined,
    },
    downloadable: { default: true, type: Boolean },
    downloadFileName: { default: "table-export", type: String },
    searchable: { default: true, type: Boolean },
    rowClickHandler: Function,
  },
  computed: {
    computedRowKey() {
      if (typeof this.rowKey === 'function') {
        return (row: Record<string, unknown>) => (this.rowKey as (row: Record<string, unknown>) => unknown)(row);
      } else if (typeof this.rowKeyLambda === 'function') {
        return (row: Record<string, unknown>) => (this.rowKeyLambda as (row: Record<string, unknown>) => unknown)(row);
      } else {
        return this.rowKey;
      }
    },
  },
  watch: {
    tableFilter: function () {
      this.initiateFilter();
    },
  },
  methods: {
    download(data: unknown, columns: unknown) {
      downloadTable(
        (this.$refs.bmTable as BmTableRef).filteredSortedRows,
        columns,
        this.$props.downloadFileName,
      );
    },
    initiateFilter() {
      this.$data.filter = this.$props.tableFilter || "";
    },
    filterMethod(rows, terms) {
      // Checks if any of the columns of a row contain the filtered term.
      const lowerTerms = terms ? terms.toLowerCase() : "";
      const columns = this.$props.tableColumns
        ? (this.$props.tableColumns as { field: string }[]).map((x) => x.field)
        : [];
      const filteredRows = rows.filter((row) =>
        columns.some((col) =>
          (row[col] + "").toLowerCase().includes(lowerTerms),
        ),
      );
      this.$data.filteredRowCount = filteredRows.length;
      return filteredRows;
    },
  },
  beforeMount() {
    if (this.$props.tableFilter) {
      this.initiateFilter();
    }
  },
});
