
import { SourceModel, SourceTypeModel } from "@/models/source";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    sources: {
      type: Array as () => SourceModel[],
    },
    rightAlign: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    parseDate(updated) {
      if (updated != null) {
        const options: Intl.DateTimeFormatOptions = {
          timeZone: "Europe/Amsterdam",
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return new Date(updated).toLocaleString("nl-NL", options);
      } else {
        return "ophaaldatum onbekend";
      }
    },
    alignment() {
      return this.$props.rightAlign
        ? "text-align: right;"
        : "text-align: left;";
    },
  },
  computed: {
    filteredSources: function (): SourceModel[] {
      // Reduce the list of types in a source to a single element if all the dates are the same
      const output = this.sources as SourceModel[];
      for (const source of output) {
        const sameDates = (s: SourceModel) =>
          s.types.every(
            (t: SourceTypeModel) => t.updated === s.types[0].updated,
          );
        if (sameDates(source)) {
          source.types = [source.types[0]];
        }
      }
      return output;
    },
  },
});
