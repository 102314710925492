import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "col-xs-12 col-lg-6" }
const _hoisted_2 = {
  key: 0,
  class: "home text-primary text-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, {
      class: "card",
      square: "",
      flat: ""
    }, {
      default: _withCtx(() => [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
      ]),
      _: 1
    })
  ]))
}