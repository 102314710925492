
import {
  getBromonitor,
  getBromonitorRecent,
  getBromonitorDates,
} from "@/api/bromonitor";

import {
  documentTypeLegendData,
  qualityRegimeLegendData,
} from "@/utils/legendData";
import LegendTable from "@/components/LegendTable.vue";
import BaseMapSvg from "@/views/reports/bronhoudermaps/BaseMapSvg.vue";
import { BromonitorModel, BromonitorModelRecent } from "@/models/aggregate";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    LegendTable,
    BaseMapSvg,
  },
  data() {
    const dateOptions = {
      timeZone: "Europe/Amsterdam",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const loaded = false;
    // const model: BromonitorModel | BromonitorModelRecent | null = null;
    // const dates = [];
    // const selectedDate = null;
    const objectLegendData = documentTypeLegendData;
    const regimeLegendData = qualityRegimeLegendData;
    const optionSelected = "cpt-per-jaar-lijn";
    const trendOptions = [
      { label: "BHR-GT", value: "bhrgt-per-jaar-lijn" },
      { label: "BHR-P", value: "bhrp-per-jaar-lijn" },
      { label: "CPT", value: "cpt-per-jaar-lijn" },
      { label: "GMN", value: "gmn-per-jaar-lijn" },
      { label: "GMW", value: "gmw-per-jaar-lijn" },
      { label: "GLD", value: "gld-per-jaar-lijn" },
      { label: "SFR", value: "sfr-per-jaar-lijn" },
      { label: "GAR", value: "gar-per-jaar-lijn" },
      { label: "GUF", value: "guf-per-jaar-lijn" },
      { label: "GPD", value: "gpd-per-jaar-lijn" }
    ];
    const scTimer = 0;
    const scY = 0;
    // Separate v-models for each accordeon to manage aria-expanded labelling.
    const actieveBronhoudersExpanded = false;
    const aantalRegistratiesExpanded = false;
    const gldExpanded = false;
    const gemeentenExpanded = false;
    const provinciesExpanded = false;
    const waterschappenExpanded = false;
    const top20Expanded = false;
    const trendsExpanded = false;
    const standExpanded = false;
    const actualiteitExpanded = false;
    return {
      dateOptions,
      dates: [] as string[],
      selectedDate: null as string | null,
      loaded,
      model: null as BromonitorModel | BromonitorModelRecent | null,
      objectLegendData,
      regimeLegendData,
      optionSelected,
      trendOptions,
      scTimer,
      scY,
      actieveBronhoudersExpanded,
      aantalRegistratiesExpanded,
      gldExpanded,
      gemeentenExpanded,
      provinciesExpanded,
      waterschappenExpanded,
      top20Expanded,
      trendsExpanded,
      standExpanded,
      actualiteitExpanded,
    };
  },
  props: {
    date: String,
  },
  methods: {
    is_empty(obj) {
      return JSON.stringify(obj) === ""
    },


    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      if (this.scTimer > 0) return;
      this.scTimer = window.setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    urlContainsDate() {
      return window.location.href.includes(this.$data.selectedDate || "");
    },
    isOldestMonitor() {
      const currentDate = this.$data.selectedDate as string;
      const datesWithoutTime = this.$data.dates.map(date => date.split('T')[0]);
      const currentIndex = datesWithoutTime.indexOf(currentDate);
      return currentIndex == this.$data.dates.length - 1;
    },
    isMostRecentMonitor() {
      const currentDate = this.$data.selectedDate as string;
      const datesWithoutTime = this.$data.dates.map(date => date.split('T')[0]);
      const currentIndex = datesWithoutTime.indexOf(currentDate);
      return currentIndex == 0;
    },
    previousMonitor() {
      const currentDate = this.$data.selectedDate as string;
      const datesWithoutTime = this.$data.dates.map(date => date.split('T')[0]);
      const currentIndex = datesWithoutTime.indexOf(currentDate);
      const previousMonitorIndex = currentIndex + 1;
      const nextDate = this.$data.dates[previousMonitorIndex].split("T")[0];
      this.$router.push({
        name: "bro-monitor",
        query: { datum: nextDate },
      });
    },
    nextMonitor() {
      const currentDate = this.$data.selectedDate as string;
      const datesWithoutTime = this.$data.dates.map(date => date.split('T')[0]);
      const currentIndex = datesWithoutTime.indexOf(currentDate);
      const nextMonitorIndex = currentIndex - 1;
      const nextDate = this.$data.dates[nextMonitorIndex].split("T")[0];
      this.$router.push({
        name: "bro-monitor",
        query: { datum: nextDate },
      });
    },
    loadBroMonitor() {
      const queryDate = this.$props.date as string;
      const queryDateTime = queryDate + "T00:00:00.000Z";

      const fetchData = async (): Promise<void> => {
        this.$data.dates = (await getBromonitorDates()).dates.map((date) => {
          const d = new Date(date);
          return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
        });

        let fetchMonitor = async (): Promise<void> => { };
        const datesWithoutTime = this.$data.dates.map(date => date.split('T')[0]);
        const index = datesWithoutTime.indexOf(queryDate);

        // Date is found in the list, fetch that date
        if (queryDate && index != -1) {
          fetchMonitor = async (): Promise<void> => {
            if (index == 0) {
              this.$data.model = await getBromonitorRecent();
            } else {
              this.$data.model = await getBromonitor(queryDateTime);
            }
            this.$data.selectedDate = queryDate;
            this.$data.loaded = true;
          };
        }
        // Date does not exist, redirect
        else if (queryDate && index == -1) {
          this.$router.push({ name: "not-found" });
        }
        // No date given, fetch most recent
        else {
          fetchMonitor = async (): Promise<void> => {
            this.$data.model = await getBromonitorRecent();
            this.$data.selectedDate = this.$data.dates[0].split("T")[0];
            this.$data.loaded = true;
          };
        }
        fetchMonitor();
      };
      fetchData();
    },
    handleClick(e) {
      if (e.target.matches(".assistive-button")) {
        const idTable = e.target.id.substr(7);
        const element = document.getElementById(idTable);
        if (element != null) {
          if (!element.classList.contains("assistive")) {
            element.classList.add("assistive");
            element.classList.add("hidden");
            e.target.innerHTML = "Toon datatabel";
          } else {
            element.classList.remove("assistive");
            element.classList.remove("hidden");
            e.target.innerHTML = "Verberg datatabel";
          }
        }
      }
    },
    hideTrends() {
      this.trendOptions.forEach((option) => {
        const element = document.getElementById(option.value);
        if (element != null && option.value != this.optionSelected) {
          if (!element.classList.contains("hidden")) {
            element.classList.add("hidden");
          }
        }
      });
    },
    changedObjecttype() {
      this.hideTrends();
      const element = document.getElementById(this.$data.optionSelected);
      if (element != null) {
        if (element.classList.contains("hidden")) {
          element.classList.remove("hidden");
        }
      }
    },
  },
  watch: {
    date: function () {
      this.loadBroMonitor();
    },
  },
  mounted() {
    this.loadBroMonitor();
    window.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    if (!this.urlContainsDate()) {
      this.hideTrends();
    }
  },
});
