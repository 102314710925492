
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    logoSize() {
      return this.$q.screen.lt.md ? "logo-fallback-ro.svg" : "logo-ro.svg";
    },
  },
});
