
import { defineComponent } from "vue";
import Breadcrumbs from "@/components/headers/Breadcrumbs.vue";
import RijksoverheidLogo from "@/components/headers/RijksoverheidLogo.vue";
import SkipLink from "@/components/headers/SkipLink.vue";

export default defineComponent({
  components: {
    Breadcrumbs,
    RijksoverheidLogo,
    SkipLink,
  },
  props: {
    cssOverride: {
      default:
        "offset-xs-0 col-xs-12 offset-md-2 col-md-8 q-px-xs-none q-px-lg-lg",
      type: String,
    },
  },
});
