
import { getPageVisits } from "@/api/pageVisits";
import { CountAggregate } from "@/models/aggregate";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    const dataWeek: CountAggregate[] = [];
    const dataMonth: CountAggregate[] = [];
    const loaded = false;
    return { dataWeek, dataMonth, loaded };
  },
  methods: {
    loadData() {
      const fetchData = async (): Promise<void> => {
        this.$data.dataWeek = await getPageVisits(7);
        this.$data.dataMonth = await getPageVisits(28);
        this.$data.loaded = true;
      };
      fetchData();
    },
  },
  beforeMount() {
    this.loadData();
  },
});
