import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_page_title = _resolveComponent("page-title")!
  const _component_textbox = _resolveComponent("textbox")!
  const _component_bm_table = _resolveComponent("bm-table")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_spinner, { loaded: _ctx.loaded }, null, 8, ["loaded"]),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_page_title, {
          text: _ctx.$t('analyse_1.titel')
        }, null, 8, ["text"]),
        _createVNode(_component_textbox, {
          text: _ctx.$t('analyse_1.uitleg')
        }, null, 8, ["text"]),
        _createVNode(_component_bm_table, {
          tableData: _ctx.countData,
          tableColumns: _ctx.columns,
          tableFilter: _ctx.bronhouder,
          "row-key": "kvk"
        }, null, 8, ["tableData", "tableColumns", "tableFilter"])
      ], 512), [
        [_vShow, _ctx.loaded]
      ])
    ]),
    _: 1
  }))
}