import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bro_header = _resolveComponent("bro-header")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_bro_footer = _resolveComponent("bro-footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hhh lpr fff" }, {
    default: _withCtx(() => [
      _createVNode(_component_bro_header),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_page_container, {
          class: "offset-xs-0 col-xs-12 offset-md-2 col-md-8 q-px-xs-none q-px-lg-lg content-wrapper",
          id: "content-wrapper",
          tabindex: "-1"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        })
      ]),
      _createVNode(_component_bro_footer)
    ]),
    _: 3
  }))
}