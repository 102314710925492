
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    data: {
      type: Array as () => { title: string; colour: string }[],
      default() {
        return [];
      },
    },
    legendId: String,
  },
});
