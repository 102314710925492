import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "broid-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.legendId,
    class: "legend"
  }, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.title
          }, [
            _createElementVNode("td", null, [
              _createVNode(_component_q_icon, {
                name: "stop",
                size: "2rem",
                style: _normalizeStyle({ color: item.colour })
              }, null, 8, ["style"])
            ]),
            _createElementVNode("td", null, _toDisplayString(item.title), 1)
          ]))
        }), 128))
      ])
    ])
  ], 8, _hoisted_1))
}